// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
    can_update_email_admin,
    can_get_email_admin,
} from "../helper/permissionsCheck";

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //
const adminEmail =
can_update_email_admin ||
can_get_email_admin 
    ? {
        id: "AdminEmail",
        title: "Admin Email",
        icon: icons.adminemail,
        type: "group",
        children: [
          {
            id: "admin-email",
            title: "Admin Email",
            type: "item",
            url: "/email/admin-email",
            icon: icons.adminemail,
            breadcrumbs: true,
          },
        ],
      }
    : null;

export default adminEmail;

