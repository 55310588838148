import { ALL_CAMPAIGNS_GET } from "../../constant/ActionType";

export default function AllCampaignsGet(
  state = {
    campaignssData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_CAMPAIGNS_GET:
      return {
        ...state,
        campaignssData: action?.data?.data,
      };

    default:
  }

  return state;
}
