import shop from "../shop/shop";
import * as types from "../constant/ActionType";

export const fssetchsogsinUser = (data) => ({
  type: types.FETCH_LOGIN_DATA,
  data,
});
export const ForgetPasswordz = (data) => ({
  type: types.FORGET_PASSWORD,
  data,
});

export const specificroless = (data) => ({
  type: types.CREATE_ROLE,
  data,
});

export const Childassigpermissions = (data) => ({
  type: types.ASSIGN_PERMISSIONS,
  data,
});

export const childALLPermisssions = (data) => ({
  type: types.GETALL_PERMISSION,
  data,
});
export const rolePermissions = (data) => ({
  type: types.ROLE_PERMISSIONS,
  data,
});

export const contantAllviewroless = (data) => ({
  type: types.VIEW_ROLES,
  data,
});
export const viewOfAllUsers = (data) => ({
  type: types.VIEW_USERS,
  data,
});
export const viewOfAllProducts = (data) => ({
  type: types.VIEW_PRODUCTS,
  data,
});

export const updateStatusUser = (data) => ({
  type: types.UPDATE_STATUS_USERS,
  data,
});

export const edituserChild = (data) => ({
  type: types.EDIT_USER,
  data,
});

export const categorystatusupdate = (data) => ({
  type: types.STATUS_UPDATE_CATEGORY,
  data,
});

export const subcategoryupdatestats = (data) => ({
  type: types.SUB_CATEGORY_SATUS_UPDATE,
  data,
});

export const allcount = (data) => ({
  type: types.All_COUNTS,
  data,
});

export const PostChangePassword = (data) => ({
  type: types.CHANGE_PASSWORD,
  data,
});

export const exportCsvcategory = (data) => ({
  type: types.EXPORT_CSV_CATEGORYIES,
  data,
});

export const exportcsvCodeRandoms = (data) => ({
  type: types.EXPORT_CSV_RANDOM_CODE,
  data,
});

export const mapData = (data) => ({
  type: types.MAP_DATA,
  data,
});

export const allNotificationData = (data) => ({
  type: types.GET_ALL_NOTIFICATION,
  data,
});

export const allTaxesData = (data) => ({
  type: types.GET_ALL_TAXES,
  data,
});
export const createTaxesData = (data) => ({
  type: types.CREATE_TAXES,
  data,
});
export const updateTaxesData = (data) => ({
  type: types.UPDATE_TAXES,
  data,
});
export const updateStatusTaxesData = (data) => ({
  type: types.UPDATE_TAXES_STATUS,
  data,
});

export const GetAllCountry = (data) => ({
  type: types.GET_ALL_COUNTRY,
  data,
});

export const PrescriptionCreate = (data) => ({
  type: types.CREATE_ALL_PRESCRIPTION,
  data,
});

export const UpdateAppointments = (data) => ({
  type: types.UPDATE_APPOINTMENTS,
  data,
});

export const GetAllDoctors = (data) => ({
  type: types.GET_ALL_DOCTORS,
  data,
});

export const CreateAllDoctors = (data) => ({
  type: types.CREATE_ALL_DOCTORS,
  data,
});

export const AllDrEdit = (data) => ({
  type: types.EDIT_ALL_DOCTORS,
  data,
});

export const AllDrDelete = (data) => ({
  type: types.DELETE_ALL_DOCTORS,
  data,
});

export const CategoryDoctors = (data) => ({
  type: types.ALL_DOCTORS_CATEGORY,
  data,
});

export const CategoryCrDoctors = (data) => ({
  type: types.CREATE_DOCTORS_CATEGORY,
  data,
});

export const CategoryEditDoctors = (data) => ({
  type: types.EDIT_DOCTORS_CATEGORY,
  data,
});

export const QualificationCrDoctors = (data) => ({
  type: types.CREATE_DOCTORS_QUALIFICATION,
  data,
});
export const allSypmtoms = (data) => ({
  type: types.GET_ALL_SYMPTOMS,
  data,
});

export const QualificationCreate = (data) => ({
  type: types.CREATE_QUALIFICATION,
  data,
});
export const createSymptoms = (data) => ({
  type: types.CREATE_SYMPTOM,
  data,
});

export const QualificationEdit = (data) => ({
  type: types.EDIT_QUALIFICATION,
  data,
});
export const editSymptoms = (data) => ({
  type: types.EDIT_SYMPTOM,
  data,
});

export const AllDrDetails = (data) => ({
  type: types.DETAILS_DOCTORS,
  data,
});

export const AllCountryAcDc = (data) => ({
  type: types.ACTIVE_COUNTRY_DEACTIVE,
  data,
});

export const AllCountryEdit = (data) => ({
  type: types.COUNTRY_EDIT,
  data,
});

export const AllCountryCrc = (data) => ({
  type: types.ALL_COUNTRY_CREATE,
  data,
});


export const AllCampaignsGet = (data) => ({
  type: types.ALL_CAMPAIGNS_GET,
  data,
});
export const AllCampaignsCreate = (data) => ({
  type: types.ALL_CAMPAIGNS_CREATE,
  data,
});
export const AllCampaignsedit = (data) => ({
  type: types.ALL_CAMPAIGNS_EDIT,
  data,
});



export const CampaignsUpdatestatus = (data) => ({
  type: types.CAMPAIGNS_UPDATE_STATUS,
  data,
});


export const AllGetReports = (data) => ({
  type: types.ALL_GET_REPORTS,
  data,
});

export const AllPateinsDetls = (data) => ({
  type: types.ALL_PATIENTS_DETAILS,
  data,
});

export const AllAppointmentReports = (data) => ({
  type: types.ALL_APPOINTMENTS_REPORTS,
  data,
});

export const GetPatientReports = (data) => ({
  type: types.ALL_PATIENT_REPORTS,
  data,
});

export const DeleteCountry = (data) => ({
  type: types.ALL_COUNTRY_DELETE,
  data,
});


export const DeletePatienthistory = (data) => ({
  type: types.PATIENT_HISTORY_DELETE,
  data,
});

export const DeletePatients = (data) => ({
  type: types.ALL_PATIENTS_DELETE,
  data,
});

export const TemplateGetAll = (data) => ({
  type: types.GET_ALL_TEMPLATE,
  data,
});

export const TemplateEdittt = (data) => ({
  type: types.UPDATE_ALL_TEMPLATE,
  data,
});

export const AllRatingss = (data) => ({
  type: types.GIVE_ALL_RATING,
  data,
});

export const AllReportDrExport = (data) => ({
  type: types.DOCTOR_REPORTS_EXPORT,
  data,
});

export const AllReportPtExport = (data) => ({
  type: types.PATIENT_REPORTS_EXPORT,
  data,
});

export const ReportExportAll = (data) => ({
  type: types.REPORTS_ALL_EXPORT,
  data,
});

export const getAllActiveTaxData = (data) => ({
  type: types.GET_ALL_ACTIVE_TAX,
  data,
});

export const createAddPayment = (data) => ({
  type: types.CREATE_PAYMENT,
  data,
});

export const createCategory = (data) => ({
  type: types.CREATE_CATEGORY,
  data,
});
export const removeChildNoti = (data) => ({
  type: types.REMOVE_NOTIFICATION,
  data,
});

export const getAllPayment = (data) => ({
  type: types.GET_PAYMENT,
  data,
});

export const PatientHistoryEdit = (data) => ({
  type: types.UPDATE_PATIENT_HISTORY,
  data,
});

export const PrescriptionsAll = (data) => ({
  type: types.GET_ALL_PRESCRIPTION,
  data,
});

export const PrescriptionsEdit = (data) => ({
  type: types.EDIT_ALL_PRESCRIPTION,
  data,
});

export const DocotrsUpdate = (data) => ({
  type: types.UPDATE_DOCTORS,
  data,
});

export const DoctorsCategoryAcDc = (data) => ({
  type: types.ACTIVE_DOCTORS_DEACTIVE,
  data,
});

export const AppointmentBooking = (data) => ({
  type: types.ASSISTED_APPOINTMENTS_BOOKING,
  data,
});

export const DateTimeAppoint = (data) => ({
  type: types.APPOITNMENT_DATETIME_SLOT,
  data,
});
export const getDoctorServices = (data) => ({
  type: types.DOCTOR_SERVICES,
  data,
});

// <-------------------------- Users ------------------------>
export const createAddUsers = (data) => ({
  type: types.CREATE_ADD_USERS,
  data,
});
export const editUpdateUser = (data) => ({
  type: types.EDIT_UPDATE_USERS,
  data,
});
export const getAllUsers = (data) => ({
  type: types.GET_ALL_USERS,
  data,
});

export const allNotification = (data) => ({
  type: types.ALL_NOTIFICATION,
  data,
});

export const getsLotsChildd = (data) => ({
  type: types.GET_ALL_SLOTS,
  data,
});

export const allappoitdatachild = (data) => ({
  type: types.ALL_APPOINTMENTS,
  data,
});

export const editchildSlot = (data) => ({
  type: types.EDIT_SLOTS,
  data,
});

export const allgetchilDpatient = (data) => ({
  type: types.ALL_PATIENTS,
  data,
});

export const PatientsHistory = (data) => ({
  type: types.ALL_PATIENTS_HISTORY,
  data,
});

export const PatientsCrHistory = (data) => ({
  type: types.CREATE_PATIENTS_HISTORY,
  data,
});

export const doctdepartmentChildDele = (data) => ({
  type: types.STATUS_UPDATE_DOCTOR_DEPARTMENT,
  data,
});

export const childfetchdoctorqualification = (data) => ({
  type: types.DELETE_QUALIFICATIONS,
  data,
});

export const dahsboarChilddata = (data) => ({
  type: types.STATICS_DASHBOARD_DATA,
  data,
});

export const dahsboarGraphdata = (data) => ({
  type: types.GRAPH_DASHBOARD_DATA,
  data,
});

export const editpatientchildd = (data) => ({
  type: types.EDIT_PATIENT,
  data,
});

export const symptomschildupdate = (data) => ({
  type: types.SYMPTOMS_STATUS_UPDATE,
  data,
});

export const handledeletePresccc = (data) => ({
  type: types.DELETE_PRESCRIPTIONS,
  data,
});

export const createrefuChild = (data) => ({
  type: types.CREATE_CANCEL_REFUND_CRUD,
  data,
});

export const getRefunds = (data) => ({
  type: types.ALL_REFUND_APPOINTMENT,
  data,
});

export const getRefundStatus = (data) => ({
  type: types.ALL_REFUND_STATUS_UPDATE,
  data,
});

export const childfetchDocPss = (data) => ({
  type: types.DOCTOR_PASSWORD_UPDATE,
  data,
});


export const GetAlladminemails = (data) => ({
  type: types.GET_ADMIN_EMAILS,
  data,
});

export const PostAlladminemails = (data) => ({
  type: types.POST_ADMIN_EMAILS,
  data,
});




export const EditFetchUpdateDoctorPasswddd = (data) => (dispatch) => {
  shop.EditFetchUpdateDoctorPasswddd(data, (e) => {
    dispatch(childfetchDocPss(e, data));
  });
};

export const UpdateStatusRefundss = (data) => (dispatch) => {
  shop.AllrefundStatuss(data, (e) => {
    dispatch(getRefundStatus(e, data));
  });
};

export const GetAllRefundss = (data) => (dispatch) => {
  shop.Allrefundget(data, (e) => {
    dispatch(getRefunds(e, data));
  });
};

export const HandleCrudRefundss = (data) => (dispatch) => {
  shop.HandleCrudRefundss(data, (e) => {
    dispatch(createrefuChild(e, data));
  });
};

export const FetchDeletePrescriptionsss = (data) => (dispatch) => {
  shop.FetchDeletePrescriptionsss(data, (e) => {
    dispatch(handledeletePresccc(e, data));
  });
};

export const UpdateStatusSymptomFetchNew = (data) => (dispatch) => {
  shop.UpdateStatusSymptoms(data, (e) => {
    dispatch(symptomschildupdate(e, data));
  });
};

export const FetchEditPatientSpeicfic = (data) => (dispatch) => {
  shop.FetchEditPatient(data, (e) => {
    dispatch(editpatientchildd(e, data));
  });
};

export const AllDataDashboardFetch = (data) => (dispatch) => {
  shop.AllDataDashboardFetch(data, (e) => {
    dispatch(dahsboarChilddata(e, data));
  });
};

export const AllGraphDashboard = (data) => (dispatch) => {
  shop.AllDataGraph(data, (e) => {
    dispatch(dahsboarGraphdata(e, data));
  });
};

export const FetchDoctorQualificationDelete = (data) => (dispatch) => {
  shop.FetchDoctorQualificationDelete(data, (e) => {
    dispatch(childfetchdoctorqualification(e, data));
  });
};

export const FetchStatusUpdateDepartment = (data) => (dispatch) => {
  shop.FetchStatusUpdateDepartment(data, (e) => {
    dispatch(doctdepartmentChildDele(e, data));
  });
};

export const AllFetchPatinetS = (data) => (dispatch) => {
  shop.AllFetchPatinetS(data, (e) => {
    dispatch(allgetchilDpatient(e, data));
  });
};

export const AllPatientsHistory = (data) => (dispatch) => {
  shop.GetPatientsHistory(data, (e) => {
    dispatch(PatientsHistory(e, data));
  });
};

export const CreatePatientsHistory = (data) => (dispatch) => {
  shop.PatientsHistoryCr(data, (e) => {
    dispatch(PatientsCrHistory(e, data));
  });
};

export const FetchEditslotStore = (data) => (dispatch) => {
  shop.FetchEditslotStore(data, (e) => {
    dispatch(editchildSlot(e, data));
  });
};

export const FetchAllApointmentsData = (data) => (dispatch) => {
  shop.FetchAllApointmentsData(data, (e) => {
    dispatch(allappoitdatachild(e, data));
  });
};

export const FetchAllSlotsStore = (data) => (dispatch) => {
  shop.FetchAllSlotsStore(data, (e) => {
    dispatch(getsLotsChildd(e, data));
  });
};

export const GetAllActiveTax = (data) => (dispatch) => {
  shop.GetAllActiveTax(data, (e) => {
    dispatch(getAllActiveTaxData(e, data));
  });
};

export const REmoveFetchNotifiction = (data) => (dispatch) => {
  shop.REmoveFetchNotifiction(data, (e) => {
    dispatch(removeChildNoti(e, data));
  });
};

export const PatientHIstEdit = (data) => (dispatch) => {
  shop.UpdatePatientHistory(data, (e) => {
    dispatch(PatientHistoryEdit(e, data));
  });
};

export const AllPrescription = (data) => (dispatch) => {
  shop.GetPrescriptions(data, (e) => {
    dispatch(PrescriptionsAll(e, data));
  });
};

export const EditPrescription = (data) => (dispatch) => {
  shop.UpdatePrescriptions(data, (e) => {
    dispatch(PrescriptionsEdit(e, data));
  });
};

export const DocotrssEdit = (data) => (dispatch) => {
  shop.UpdateDocotrs(data, (e) => {
    dispatch(DocotrsUpdate(e, data));
  });
};

export const DoctorsCatgAll = (data) => (dispatch) => {
  shop.CategoryAllDoctors(data, (e) => {
    dispatch(DoctorsCategoryAcDc(e, data));
  });
};

export const CreateAppointBook = (data) => (dispatch) => {
  shop.BookAppointment(data, (e) => {
    dispatch(AppointmentBooking(e, data));
  });
};

export const AppointDtSlot = (data) => (dispatch) => {
  shop.DateTimeSlot(data, (e) => {
    dispatch(DateTimeAppoint(e, data));
  });
};
export const FetchDocterServices = (data) => (dispatch) => {
  shop.GetDoctorServices(data, (e) => {
    dispatch(getDoctorServices(e, data));
  });
};
// <----------------------Dashboard--------------------->
export const getAllNotification = (data) => (dispatch) => {
  shop.getAllNotification(data, (e) => {
    dispatch(allNotificationData(e, data));
  });
};

export const GetAllTaxes = (data) => (dispatch) => {
  shop.GetTaxes(data, (e) => {
    dispatch(allTaxesData(e, data));
  });
};

export const CreateTaxes = (data) => (dispatch) => {
  shop.CreateTaxes(data, (e) => {
    dispatch(createTaxesData(e, data));
  });
};

export const UpdateTaxes = (data) => (dispatch) => {
  shop.UpdateTaxes(data, (e) => {
    dispatch(updateTaxesData(e, data));
  });
};
export const UpdateStatusTaxes = (data) => (dispatch) => {
  shop.UpdateStatusTaxes(data, (e) => {
    dispatch(updateStatusTaxesData(e, data));
  });
};

export const CreateAddPayment = (data) => (dispatch) => {
  shop.CreateAddPayment(data, (e) => {
    dispatch(createAddPayment(e, data));
  });
};

export const createCategorY = (data) => (dispatch) => {
  shop.createCategory(data, (e) => {
    dispatch(createCategory(e, data));
  });
};

export const GetAllPayments = (data) => (dispatch) => {
  shop.GetAllPayments(data, (e) => {
    dispatch(getAllPayment(e, data));
  });
};

export const CreateAddUsers = (data) => (dispatch) => {
  shop.CreateAddUsers(data, (e) => {
    dispatch(createAddUsers(e, data));
  });
};
export const EditUpdateUser = (data) => (dispatch) => {
  shop.EditUpdateUsers(data, (e) => {
    dispatch(editUpdateUser(e, data));
  });
};
export const GetAllUsers = (data) => (dispatch) => {
  shop.GetAllUsers(data, (e) => {
    dispatch(getAllUsers(e, data));
  });
};

export const AllNotification = (data) => (dispatch) => {
  shop.AllNotification(data, (e) => {
    dispatch(allNotification(e, data));
  });
};

export const fetchLoginUser = (data) => (dispatch) => {
  shop.fetchLoginUser(data, (e) => {
    dispatch(fssetchsogsinUser(e, data));
  });
};
export const ForgetPassword = (data) => (dispatch) => {
  shop.ForgetPassword(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};
export const VerifyOTP = (data) => (dispatch) => {
  shop.VerifyOTP(data, (e) => {
    dispatch(ForgetPasswordz(e, data));
  });
};

export const CreateFetchRolee = (data) => (dispatch) => {
  shop.CreateFetchRolee(data, (e) => {
    dispatch(specificroless(e, data));
  });
};
export const EditRole = (data) => (dispatch) => {
  shop.EditRole(data, (e) => {
    dispatch(specificroless(e, data));
  });
};

export const FetchAllUsersView = (data) => (dispatch) => {
  shop.FetchAllUsersView(data, (e) => {
    dispatch(viewOfAllUsers(e, data));
  });
};

export const FetchUsersStatusUpdateView = (data) => (dispatch) => {
  shop.FetchUsersStatusUpdateView(data, (e) => {
    dispatch(updateStatusUser(e, data));
  });
};

export const FetchAllRoleView = (data) => (dispatch) => {
  shop.FetchAllRoleView(data, (e) => {
    dispatch(contantAllviewroless(e, data));
  });
};

export const AssignPermissionsShop = (data) => (dispatch) => {
  shop.AssignPermissionsShop(data, (e) => {
    dispatch(Childassigpermissions(e, data));
  });
};

export const getAllPermissions = (data) => (dispatch) => {
  shop.getAllPermissions(data, (e) => {
    dispatch(childALLPermisssions(e, data));
  });
};

export const GetRolePermissions = (data) => (dispatch) => {
  shop.GetRolePermissions(data, (e) => {
    dispatch(rolePermissions(e, data));
  });
};

export const HandleEditUserApi = (data) => (dispatch) => {
  shop.HandleEditUserApi(data, (e) => {
    dispatch(edituserChild(e, data));
  });
};

export const AllCounts = (data) => (dispatch) => {
  shop.GetAllCounts(data, (e) => {
    dispatch(allcount(e, data));
  });
};

export const AllTransactions = (data) => (dispatch) => {
  shop.GetAllCounts(data, (e) => {
    dispatch(allcount(e, data));
  });
};

export const StatusUpdateBatches = (data) => (dispatch) => {
  shop.StatusUpdateBatches(data, (e) => { });
};

export const ChangePassword = (data) => (dispatch) => {
  shop.ChangePassword(data, (e) => {
    dispatch(PostChangePassword(e, data));
  });
};

export const GetAllQrcodeReport = (data) => (dispatch) => {
  shop.GetAllQrcodeReport(data, (e) => { });
};



export const GetAdminEmails = (data) => (dispatch) => {
  shop.AdminEmailsAll(data, (e) => {
    dispatch(GetAlladminemails(e, data));
  });
};


export const PostAdminEmails = (data) => (dispatch) => {
  shop.PostAdminEmailsAll(data, (e) => {
    dispatch(PostAlladminemails(e, data));
  });
};






export const AllCountry = (data) => (dispatch) => {
  shop.CountryAll(data, (e) => {
    dispatch(GetAllCountry(e, data));
  });
};

export const EditAppointment = (data) => (dispatch) => {
  shop.AppointmentEdit(data, (e) => {
    dispatch(UpdateAppointments(e, data));
  });
};

export const CreatePrescription = (data) => (dispatch) => {
  shop.PrescriptionPost(data, (e) => {
    dispatch(PrescriptionCreate(e, data));
  });
};

export const AllDoctors = (data) => (dispatch) => {
  shop.DoctorsAll(data, (e) => {
    dispatch(GetAllDoctors(e, data));
  });
};

export const CreateDoctors = (data) => (dispatch) => {
  shop.DoctorsCreate(data, (e) => {
    dispatch(CreateAllDoctors(e, data));
  });
};

export const EditDoctor = (data) => (dispatch) => {
  shop.DoctorsEdit(data, (e) => {
    dispatch(AllDrEdit(e, data));
  });
};

export const DeleteDoctor = (data) => (dispatch) => {
  shop.DoctorsDelete(data, (e) => {
    dispatch(AllDrDelete(e, data));
  });
};

export const AllDoctorsCategory = (data) => (dispatch) => {
  shop.DoctorsCategory(data, (e) => {
    dispatch(CategoryDoctors(e, data));
  });
};

export const CreateDoctorsCategory = (data) => (dispatch) => {
  shop.DoctorsCategoryCr(data, (e) => {
    dispatch(CategoryCrDoctors(e, data));
  });
};

export const EditDoctorsCategory = (data) => (dispatch) => {
  shop.DoctorsCategoryEdit(data, (e) => {
    dispatch(CategoryEditDoctors(e, data));
  });
};

export const CreateDoctorsQualfc = (data) => (dispatch) => {
  shop.DoctorsQulification(data, (e) => {
    dispatch(QualificationCrDoctors(e, data));
  });
};

export const GetAllSymptoms = (data) => (dispatch) => {
  shop.AllSymptoms(data, (e) => {
    dispatch(allSypmtoms(e, data));
  });
};

export const CreateSymptoms = (data) => (dispatch) => {
  shop.CreateAddSymptom(data, (e) => {
    dispatch(createSymptoms(e, data));
  });
};
export const EditSymptoms = (data) => (dispatch) => {
  shop.EditUpdateSymptom(data, (e) => {
    dispatch(editSymptoms(e, data));
  });
};
export const CreateQualfc = (data) => (dispatch) => {
  shop.CreateQulification(data, (e) => {
    dispatch(QualificationCreate(e, data));
  });
};
export const EditQualfc = (data) => (dispatch) => {
  shop.EditQulification(data, (e) => {
    dispatch(QualificationEdit(e, data));
  });
};

export const DetailsDoctor = (data) => (dispatch) => {
  shop.DoctorsDetails(data, (e) => {
    dispatch(AllDrDetails(e, data));
  });
};

export const CountryActiveDeactive = (data) => (dispatch) => {
  shop.CountryAcDc(data, (e) => {
    dispatch(AllCountryAcDc(e, data));
  });
};

export const CountryEdit = (data) => (dispatch) => {
  shop.UpdateCountry(data, (e) => {
    dispatch(AllCountryEdit(e, data));
  });
};
export const CreateCountry = (data) => (dispatch) => {
  shop.CountryCreate(data, (e) => {
    dispatch(AllCountryCrc(e, data));
  });
};


export const CampaignsAllget = (data) => (dispatch) => {
  shop.GetAllCampaigns(data, (e) => {
    dispatch(AllCampaignsGet(e, data));
  });
};

export const CampaignsAllcreate = (data) => (dispatch) => {
  shop.CreateAllCampaigns(data, (e) => {
    dispatch(AllCampaignsCreate(e, data));
  });
};

export const CampaignsAlledit = (data) => (dispatch) => {
  shop.UpdateAllCampaigns(data, (e) => {
    dispatch(AllCampaignsedit(e, data));
  });
};

export const CampaignsStatusUpdate = (data) => (dispatch) => {
  shop.UpdateStatusCampaigns(data, (e) => {
    dispatch(CampaignsUpdatestatus(e, data));
  });
};

export const AllReportss = (data) => (dispatch) => {
  shop.GetReportss(data, (e) => {
    dispatch(AllGetReports(e, data));
  });
};

export const PatientsDetail = (data) => (dispatch) => {
  shop.DetailsPatients(data, (e) => {
    dispatch(AllPateinsDetls(e, data));
  });
};

export const ReportAppointments = (data) => (dispatch) => {
  shop.AllReportsAppoint(data, (e) => {
    dispatch(AllAppointmentReports(e, data));
  });
};

export const ReportPatient = (data) => (dispatch) => {
  shop.AllPatientReport(data, (e) => {
    dispatch(GetPatientReports(e, data));
  });
};

export const CountryDelete = (data) => (dispatch) => {
  shop.AllCountryDelete(data, (e) => {
    dispatch(DeleteCountry(e, data));
  });
};


export const PatientHistoryDelete = (data) => (dispatch) => {
  shop.AllpatientHistoryDelete(data, (e) => {
    dispatch(DeletePatienthistory(e, data));
  });
};


export const AllPatientsDelete = (data) => (dispatch) => {
  shop.PatientsDelete(data, (e) => {
    dispatch(DeletePatients(e, data));
  });
};

export const AllTemplatess = (data) => (dispatch) => {
  shop.GetTemplate(data, (e) => {
    dispatch(TemplateGetAll(e, data));
  });
};

export const UpdateTemplatess = (data) => (dispatch) => {
  shop.EditTemplate(data, (e) => {
    dispatch(TemplateEdittt(e, data));
  });
};

export const AddRatings = (data) => (dispatch) => {
  shop.RatingAdd(data, (e) => {
    dispatch(AllRatingss(e, data));
  });
};

export const DoctorReportExport = (data) => (dispatch) => {
  shop.DrExportReport(data, (e) => {
    dispatch(AllReportDrExport(e, data));
  });
};

export const PatientReportExport = (data) => (dispatch) => {
  shop.PtExportReport(data, (e) => {
    dispatch(AllReportPtExport(e, data));
  });
};

export const ReportssAllExport = (data) => (dispatch) => {
  shop.AllReportExports(data, (e) => {
    dispatch(ReportExportAll(e, data));
  });
};

// --------------------------------------E-CLINIC-------------------------------------------------------------------------
