import { GET_ADMIN_EMAILS } from "../../constant/ActionType";

export default function AllAdminEmails(
  state = {
    getAdminEmailsAll: [],
  },
  action
) {
  switch (action.type) {
    case GET_ADMIN_EMAILS:
      return {
        ...state,
        getAdminEmailsAll: action?.data?.data,
      };

    default:
  }

  return state;
}
