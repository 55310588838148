import { lazy, useEffect } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import {
  can_add_property_type,
  can_view_property_type,
  can_edit_property_type,
  can_add_amenities,
  can_edit_amenities,
  can_view_amenities,
  can_add_tax,
  dashboarPer_Check,
  can_edit_tax,
  can_view_tax,
  can_add_property,
  can_edit_property,
  can_view_property,
  can_add_flat,
  can_edit_flat,
  can_view_flat,
  can_add_premises_size,
  can_edit_premises_size,
  can_view_premises_size,
  can_add_clients,
  can_edit_clients,
  can_view_clients,
  can_add_payments,
  can_edit_payments,
  can_view_payments,
  can_add_assign_premises,
  can_edit_assign_premises,
  can_view_assign_premises,
  can_add_roles,
  can_edit_roles,
  can_view_roles,
  can_add_users,
  can_edit_users,
  can_view_users,
  can_add_permissions,
  can_view_notifications,
  can_delete_notifications,
  can_add_maintenance_type,
  can_view_maintenance_type,
  can_edit_maintenance_type,
  per_doctor_qualifiation,
  can_add_maintenance,
  can_view_maintenance,
  per_view_doctor,
  per_view_doctor_category,
  can_edit_maintenance,
  can_delete_maintenance,
  per_view_doctor_appointment,
  create_Appointment_Book_Permissiosn,
  Refunf_View_Per,
  per_view_slots,
  per_symptoms_view,
  per_all_patient_view,
  per_patient_history_view,
  per_prescription_view,
  per_country_per_view,
  per_template_view,
  per_view_users,
  per_view_roles,
  per_permission_view,
  can_create_campaign,
  can_delete_campaign,
  can_get_campaign,
  can_update_campaign,
  can_get_email_admin,
  can_update_email_admin,
} from "helper/permissionsCheck";
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
const AllTaxes = Loadable(lazy(() => import("pages/taxes/AllTaxes")));
const CreatePayments = Loadable(
  lazy(() => import("pages/payments/CreatePayments"))
);
const AllNotifications = Loadable(
  lazy(() => import("pages/notification/AllNotifications"))
);

const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);

// --------------------------------------------E_CLINIC--------------------------------------------------------
const DoctorsAllGet = Loadable(
  lazy(() => import("pages/doctors/AllDoctors.jsx"))
);
const CreateDoctors = Loadable(lazy(() => import("pages/doctors/crdoctor")));

const AssignCategorySymptom = Loadable(
  lazy(() => import("pages/doctors/AssignCategorySymptom"))
);

const DetailsDoctors = Loadable(
  lazy(() => import("pages/doctors/DoctorsDetail"))
);
const DoctorsCategory = Loadable(
  lazy(() => import("pages/doctors/DoctorsCategory"))
);
const DoctorsQualification = Loadable(
  lazy(() => import("pages/doctors/DoctorQualification"))
);
const AllAppointments = Loadable(
  lazy(() => import("pages/appointments/allappointments"))
);
const AllSymptomsView = Loadable(
  lazy(() => import("pages/symptoms/AllSymptomsView"))
);
const AllPaymentss = Loadable(
  lazy(() => import("pages/allpayments/AllPayments"))
);
const AllSlots = Loadable(lazy(() => import("pages/slots/AllSlots")));
const AllPatients = Loadable(lazy(() => import("pages/patients/allpatients")));
const ConstantProfile = Loadable(
  lazy(() => import("pages/patients/constantpatientprofile"))
);
const PatientsDeatilsAll = Loadable(
  lazy(() => import("pages/patients/patientDetails"))
);
const PatientsHistory = Loadable(
  lazy(() => import("pages/patients/PatientsHistory"))
);

const CreateSlots = Loadable(lazy(() => import("pages/slots/createSlots")));
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));
const RegisterUser = Loadable(
  lazy(() => import("pages/user-management/registerUser"))
);
const AllRoles = Loadable(lazy(() => import("pages/roles/allroles")));
const AppointmentReport = Loadable(
  lazy(() => import("pages/reports/appointment-reports"))
);
const patientwise = Loadable(
  lazy(() => import("pages/reports/patient-wise-report"))
);
const AllCountry = Loadable(lazy(() => import("pages/country/AllCountry")));

const PatientWiseReport = Loadable(
  lazy(() => import("pages/reports/patient-wise-report"))
);
const Prescriptions = Loadable(
  lazy(() => import("pages/prescriptions/AllPrescriptions"))
);
const Template = Loadable(lazy(() => import("pages/templates/AllTemplates")));
const TemplateUpdate = Loadable(
  lazy(() => import("pages/templates/TemplateUpdate"))
);
const AllReports = Loadable(lazy(() => import("pages/reports/AllReports")));

const BookAppointments = Loadable(
  lazy(() => import("pages/appointments/CrcAppointBook"))
);
const AllRefunds = Loadable(lazy(() => import("pages/refunds/AllRefunds")));
const Campaigns = Loadable(lazy(() => import("pages/allCampaigns/Campaigns")));
const CreateCampaigns = Loadable(
  lazy(() => import("pages/allCampaigns/CreateCampaigns"))
);

const AdminEmail = Loadable(lazy(() => import("pages/adminEmail/AdminEmail")));

// pages routing

// ==============================|| MAIN ROUTES ||============================== //
// let permissionUser = localStorage.getItem('LoginData');
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray =
  decryptedData && JSON.parse(decryptedData)?.user_type;

console.log("sdaadasndbsbdbsabdasbdbb", permissionsAssrray);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: dashboarPer_Check
        ? [
            {
              path: "/",
              element: <DashboardDefault />,
            },
            {
              path: "dashboard",
              element: <DashboardDefault />,
            },
            {
              path: "*",
              element: <DashboardDefault />,
            },
          ]
        : [],
    },

    true
      ? {
          path: "doctors",
          element: <MainLayout />,
          children: [
            per_view_doctor
              ? {
                  path: "all-doctors",
                  element: <DoctorsAllGet />,
                }
              : null,
            true
              ? {
                  path: "create-doctors",
                  element: <CreateDoctors />,
                }
              : null,
            true
              ? {
                  path: "assign-symptoms",
                  element: <AssignCategorySymptom />,
                }
              : null,
            true
              ? {
                  path: "details-doctors",
                  element: <DetailsDoctors />,
                }
              : null,
            per_view_doctor_category
              ? {
                  path: "doctors-category",
                  element: <DoctorsCategory />,
                }
              : null,
            per_doctor_qualifiation
              ? {
                  path: "doctors-qualification",
                  element: <DoctorsQualification />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "slots",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "all-slots",
                  element: <AllSlots />,
                }
              : null,
            true
              ? {
                  path: "create-slots",
                  element: <CreateSlots />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "refunds",
          element: <MainLayout />,
          children: [
            Refunf_View_Per
              ? {
                  path: "all-refunds",
                  element: <AllRefunds />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    can_update_email_admin || can_get_email_admin
      ? {
          path: "email",
          element: <MainLayout />,
          children: [
            can_update_email_admin
              ? {
                  path: "admin-email",
                  element: <AdminEmail />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "symptoms",
          element: <MainLayout />,
          children: [
            per_symptoms_view
              ? {
                  path: "all-symptoms",
                  element: <AllSymptomsView />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    can_get_campaign ||
    can_delete_campaign ||
    can_get_campaign ||
    can_update_campaign
      ? {
          path: "campaigns",
          element: <MainLayout />,
          children: [
            can_get_campaign
              ? {
                  path: "all-campaigns",
                  element: <Campaigns />,
                }
              : null,
            can_create_campaign
              ? {
                  path: "create-campaigns",
                  element: <CreateCampaigns />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "templates",
          element: <MainLayout />,
          children: [
            per_template_view
              ? {
                  path: "all-templates",
                  element: <Template />,
                }
              : null,
            true
              ? {
                  path: "templates-update",
                  element: <TemplateUpdate />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "profile",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "patient-profile",
                  element: <ConstantProfile />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    true
      ? {
          path: "slots",
          element: <MainLayout />,
          children: [
            per_view_slots
              ? {
                  path: "all-slots",
                  element: <AllSlots />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "prescription",
          element: <MainLayout />,
          children: [
            per_prescription_view
              ? {
                  path: "all-prescriptions",
                  element: <Prescriptions />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "patient",
          element: <MainLayout />,
          children: [
            per_all_patient_view
              ? {
                  path: "all-patients",
                  element: <AllPatients />,
                }
              : null,
            per_patient_history_view
              ? {
                  path: "patients-history",
                  element: <PatientsHistory />,
                }
              : null,
            {
              path: "patient-details",
              element: <PatientsDeatilsAll />,
            },
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "appointments",
          element: <MainLayout />,
          children: [
            per_view_doctor_appointment
              ? {
                  path: "all-appointments",
                  element: <AllAppointments />,
                }
              : null,
            true
              ? {
                  path: "create-doctors",
                  element: <CreateDoctors />,
                }
              : null,
            create_Appointment_Book_Permissiosn
              ? {
                  path: "assign-appointments",
                  element: <BookAppointments />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "payments",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "all-payments",
                  element: <AllPaymentss />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "users",
          element: <MainLayout />,
          children: [
            per_view_users
              ? {
                  path: "all-users",
                  element: <AllUsers />,
                }
              : null,
            true
              ? {
                  path: "register-users",
                  element: <RegisterUser />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "roles",
          element: <MainLayout />,
          children: [
            per_view_roles
              ? {
                  path: "all-roles",
                  element: <AllRoles />,
                }
              : null,
            // true
            // ? {
            //     path: "register-users",
            //     element: <RegisterUser />,
            //   }
            // : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "reports",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "appointment-report",
                  element: <AppointmentReport />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "reports",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "patient-report",
                  element: <PatientWiseReport />,
                }
              : null,
            true
              ? {
                  path: "master-report",
                  element: <AllReports />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "taxes",
          element: <MainLayout />,
          children: [
            can_add_tax || can_edit_tax || can_view_tax
              ? {
                  path: "all-taxes",
                  element: <AllTaxes />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    true
      ? {
          path: "notifications",
          element: <MainLayout />,
          children: [
            can_view_notifications
              ? {
                  path: "all-notifications",
                  element: <AllNotifications />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "country",
          element: <MainLayout />,
          children: [
            per_country_per_view
              ? {
                  path: "all-country",
                  element: <AllCountry />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "roles",
          element: <MainLayout />,
          children: [
            can_add_roles || can_edit_roles || can_view_roles
              ? {
                  path: "all-roles",
                  element: <AllRoles />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "permissions",
          element: <MainLayout />,
          children: [
            per_permission_view
              ? {
                  path: "all-permissions",
                  element: <PermissionsAssign />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "permissions",
          element: <MainLayout />,
          children: [
            per_permission_view
              ? {
                  path: "all-permissions",
                  element: <PermissionsAssign />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    // true
    //   ? {
    //       path: "users",
    //       element: <MainLayout />,
    //       children: [
    //         // allowedPermissions.includes('get-company')
    //         can_add_users || can_edit_users || can_view_users
    //           ? {
    //               path: "all-users",
    //               element: <AllUsers />,
    //             }
    //           : null,
    //       ].filter(Boolean),
    //     }
    //   : null,

    true
      ? {
          path: "users",
          element: <MainLayout />,
          children: [
            can_add_users
              ? {
                  path: "register-users",
                  element: <RegisterUser />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
  ].filter(Boolean),
};

export default MainRoutes;
