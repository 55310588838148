import { combineReducers } from "redux";
import menu from "./menu";
import CreateRolssseeeReducer from "./createrole";
import ViewAllRolesReduncerre from "./viewallrole";
import AssignPermisionsReducer from "./assignpermissions";
import GetAllPermissionReducer from "./getallassignpermissions";
import ViewAllRedUsers from "./viewAllUsers";
import EditUserDataReducer from "./edituser";
import AllCountReducers from "./allcount";
import GetRolePermissions from "./rolePermissions";
import AllAssignPreDetails from "./assignpermission/allpremisesdetails";
import Notificationgetall from "./allNotification";
import GetAllTaxes from "./AllTaxes/getAllTaxes";
import AllGetAssignPer from "./assignpermission/allpermissionassign";
import GetAllPayments from "./AllPayments/GetAllPayments";
import GetAllUsers from "./users/getAllusers";
import AllNotifications from "./notification/allnotification";
import GetAllActiveTax from "./AllTaxes/getAllActiveTax";
import GetAllDoctors from "./AllDoctors/getAllDoctors";
import AllDoctorsCategory from "./AllDoctors/DoctorsCategory";
import AllDoctorsQualifiction from "./AllDoctors/DoctorsQualfc";
import SlotsAllMainRed from "./slots/allslotsgetreduc";
import AppointmentsRedAll from "./appointments/allapointmentsdata";
import PatietnsGetAllRed from "./patients/getpatients";
import AllCountries from "./AllCountry/AllCountry";
import ActiveCountries from "./AllCountry/CountryActive";
import DetailsAllDoctors from "./AllDoctors/DoctorsDetails";
import PatietnsAllHistory from "./patients/patientshistory";
import AllDataDashboardRed from "./dashboarddatas/alldasboarddata";
import AllSymptoms from "./AllSymptoms/allSymptomsRed";
import AllGraphDashboardRed from "./dashboarddatas/dashboardgraph";
import AllDoctorsActiveDeactive from "./AllDoctors/DoctorsActiveDc";
import ReportsAppoint from "./reports/AppointmentsReport";
import ReportsPatients from "./reports/patientReport";
import GettPrescription from "./AllPrescriptions/AllPrescription";
import AllgetTemplates from "./AllTemplates/allTemplates";
import ReportsGetAll from "./reports/Allreports";
import AppointtSlotssss from "./appointments/BookAppointment";
import AllDoctorsExportRep from "./AllDoctors/DoctorReportExports";
import AllPatientExportRep from "./patients/PatientReportExports";
import ReportsExported from "./reports/ReportssExportAll";
import GetttRefunds from "./Allrefunds/GetAllRefunds";
import AllCampaignsGet from "./allCampaigns/AllCampaigns";
import DoctorServicesRedc from "./AllDoctors/DoctorServices";

import AllAdminEmails from "./adminEmails/AdminEmails";


// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  GetAllTaxes,
  GetAllUsers,
  AllNotifications,
  menu,
  CreateRolssseeeReducer,
  ViewAllRolesReduncerre,
  AssignPermisionsReducer,
  GetAllPermissionReducer,
  ViewAllRedUsers,
  EditUserDataReducer,
  AllCountReducers,
  GetRolePermissions,
  AllGetAssignPer,
  Notificationgetall,
  GetAllPayments,
  GetAllActiveTax,
  AllAssignPreDetails,
  GetAllDoctors,
  AllDoctorsCategory,
  AllDoctorsQualifiction,
  AllSymptoms,
  SlotsAllMainRed,
  AppointmentsRedAll,
  PatietnsGetAllRed,
  AllCountries,
  ActiveCountries,
  DetailsAllDoctors,
  PatietnsAllHistory,
  AllDataDashboardRed,
  AllGraphDashboardRed,
  AllDoctorsActiveDeactive,
  ReportsAppoint,
  ReportsPatients,
  GettPrescription,
  AllgetTemplates,
  ReportsGetAll,
  AppointtSlotssss,
  AllDoctorsExportRep,
  AllPatientExportRep,
  ReportsExported,
  GetttRefunds,
  AllCampaignsGet,
  DoctorServicesRedc,
  AllAdminEmails,
});

export default reducers;
