// project-imports
import dashboard from "./dashboard";

import roles from "./roles";
import Notifiicationn from "./notificationcreate";
// import support from './support';
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// import pages from './pages';
import permissions from "./permissions";
import domainParameter from "./domainParameter";
import allAssignPermissions from "./allAssignPermissions";
import AllPayments from "./Payments";
import users from "./allusers";
import doctors from "./allDoctors";
import appointments from "./allAppointments";
import payments from "./allpayments";
import slots from "./allSlots";
import alluserss from "./userManagement";
import allroles from "./allroles";
import patients from "./patientsmenuitem";
import country from "./allCountry";
import symptoms from "./allSymptoms";
import patientprofile from "./profilejustpatient";
import allreports from "./allreports";
import patientwise from "./patientwisereport";
import prescriptions from "./allprescription";
import templates from "./allTemplates";
import refund from "./AllRefunds";
import campaigns  from "./allCampaigns";
import adminEmail  from "./alladminEmail";





// ==============================|| MENU ITEMS ||============================== //
let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray = decryptedData && JSON.parse(decryptedData);
// console.log("dhhcontatatdhdhdh", permissionsAssrray?.user_type);
const menuItems = {
  items: [
    ...(dashboard ? [dashboard] : []),
    ...(doctors ? [doctors] : []),
    ...(appointments ? [appointments] : []),
    ...(refund ? [refund] : []),
    ...(patientprofile ? [patientprofile] : []),
    ...(symptoms ? [symptoms] : []),
    ...(slots ? [slots] : []),
    ...(patients ? [patients] : []),
    ...(prescriptions ? [prescriptions] : []),
    ...(country ? [country] : []),
    ...(templates ? [templates] : []),
    ...(campaigns ? [campaigns] : []),
    ...(adminEmail ? [adminEmail] : []),
    ...(allreports ? [allreports] : []),
    ...(patientwise ? [patientwise] : []),
    // ...(payments ? [payments] : []),
    ...(alluserss ? [alluserss] : []),
    ...(allroles ? [allroles] : []),

    ...(allAssignPermissions ? [allAssignPermissions] : []),
  ].filter(Boolean),
};

export default menuItems;
