// type

import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";

import icons from "helper/iconsHelper";

import {
  can_create_campaign,
  can_delete_campaign,
  can_get_campaign,
  can_update_campaign,
} from "../helper/permissionsCheck";

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const campaigns =
  can_create_campaign ||
  can_delete_campaign ||
  can_get_campaign ||
  can_update_campaign
    ? {
        id: "Campaigns",
        title: "All Campaigns",
        icon: icons.campaigns,
        type: "group",
        children: [
          can_get_campaign
            ? {
                id: "all-campaigns",
                title: "Campaign",
                type: "item",
                url: "/campaigns/all-campaigns",
                icon: icons.campaigns,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;
export default campaigns;
