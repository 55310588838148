import { DOCTOR_SERVICES } from "../../constant/ActionType";

export default function DoctorServicesRedc(
  state = {
    doctorServicesData: [],
  },
  action
) {
  switch (action.type) {
    case DOCTOR_SERVICES:
      return {
        ...state,
        doctorServicesData: action?.data,
      };

    default:
  }

  return state;
}
